<template>
  <div id="container"></div>
</template>

<script>
import { MP } from "@/utils/bmpgl.js";
export default {
  name: "",
  data() {
    return {
      ak: "ZV2MX6x3OWMV1aX9ACj15QTB32PFRj1C",
      map: null,
      geoc: null,
      BMap: null,
      coordinates: {},
      location: {},
      provinceAndCity: {
        province: "",
        city: "",
      },
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    orderLocation: {
      handler(newVal) {
        console.log("watch orderLocation", newVal);
        if (this.location === newVal) {
          console.log("this.location === newVal");
          return;
        }
        this.location = newVal;
        this.init();
      },
      deep: true,
    },
    locationList() {
      this.mark();
    },
  },
  props: ["searchAddress", "orderLocation", "unModify", "locationList"],
  methods: {
    //地图初始化
    init() {
      console.log("init()");
      let that = this;
      MP(this.ak)
        .then((BMap) => {
          that.BMap = BMap;
          // 创建地图实例
          let map = new BMap.Map("container");
          that.map = map;
          if (!that.location.lat) {
            console.log("无坐标");
            that.location = {
              lng: 119.28,
              lat: 26.08,
            };
          }
          map.centerAndZoom(
            new BMap.Point(that.location.lng, that.location.lat),
            12
          );
          if (that.unModify) {
            map.disableDragging(); //禁止拖拽
            map.disableScrollWheelZoom();
            return;
          }
          let geoc = new BMap.Geocoder();
          that.geoc = geoc;
          map.enableScrollWheelZoom(true);
          //设备地图才有locationList
          console.log("that.locationList", that.locationList);
          if (that.locationList) {
            return;
          }
          map.addEventListener("click", function (e) {
            //清除地图上所有的覆盖物
            map.clearOverlays();
            console.log(e);
            let pt = e.point;
            that.coordinates = e.point;
            let marker = new BMap.Marker(new BMap.Point(pt.lng, pt.lat));
            map.addOverlay(marker);
            geoc.getLocation(pt, function (rs) {
              let addComp = rs.addressComponents;
              console.log("addComp", addComp);
              let address = `${addComp.province}${addComp.city}${addComp.district}${addComp.street}${addComp.streetNumber}`;
              (that.provinceAndCity.province = addComp.province),
                (that.provinceAndCity.city = addComp.city);
              that.$emit("getProvince", addComp.province, addComp.city);
              that.$emit("getAddress", address);
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setPlace(myValue) {
      this.map.clearOverlays(); //清除地图上所有覆盖物
      let that = this;
      function myFun() {
				console.log('local.getResults()',local.getResults());
        if (!local.getResults()) {
          return;
        }
        var pp = local.getResults().getPoi(0).point; //获取第一个智能搜索的结果
        that.map.centerAndZoom(pp, 18);
        console.log("pp", pp);
        that.map.addOverlay(new that.BMap.Marker(pp)); //添加标注
        that.geoc.getLocation(pp, function (rs) {
          let addComp = rs.addressComponents;
          console.log("addComp", addComp);
					// let address = `${addComp.province}${addComp.city}${addComp.district}${addComp.street}${addComp.streetNumber}`;
          (that.provinceAndCity.province = addComp.province),
            (that.provinceAndCity.city = addComp.city);
          that.$emit("getProvince", addComp.province, addComp.city);
					that.$emit("getAddress", local.getResults().keyword);
        });
      }
      var local = new that.BMap.LocalSearch(that.map, {
        //智能搜索
        onSearchComplete: myFun,
      });
      local.search(myValue);
    },
    mark() {
      this.locationList.forEach((location) => {
        let marker = new this.BMap.Marker(
          new this.BMap.Point(location.lng, location.lat)
        );
        this.map.addOverlay(marker);
      });
    },
  },
};
</script>

<style scoped>
#container {
  height: 100%;
}

.BMap_cpyCtrl {
  display: none;
}

.anchorBL {
  display: none;
}
</style>
