import request from './request.js'

export function publishDevice(data) {
    return request({
        url: '/web/publishDevice/save',
        method: 'post',
        data: data
    })
}

export function publishNeed(data) {
    return request({
        url: '/web/publishNeed/save',
        method: 'post',
        data: data
    })
}
export function typeList(data) {
    return request({
        url: '/web/home/type/list',
        method: 'post',
        data: data
    })
}