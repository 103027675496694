<template>
    <div style="border: 1px solid #ccc;">
        <Toolbar
            style="border-bottom: 1px solid #ccc"
            :editor="editor"
            :defaultConfig="toolbarConfig"
            :mode="mode"
        />
        <Editor
            style="height:350px; overflow-y: hidden;"
            v-model="html"
            :defaultConfig="editorConfig"
            :mode="mode"
            @onCreated="onCreated"
            @onChange="onChange"
        />
    </div>
</template>

<script>
import Vue from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
// import { IDomEditor } from '@wangeditor/editor'
// import kits from '@/kits/index.js'

export default Vue.extend({
    name:'editor',
    components: { Editor, Toolbar },
    props:["detail_info"],
    data() {
        return {
            editor: null,
            html: this.detail_info,
            toolbarConfig: { 
              excludeKeys:[
                'group-video'
              ]
             },
            
            editorConfig: { placeholder: '请输入内容...',
            MENU_CONF: {
                    uploadImage:{
                        server: 'http://114.67.170.224:21880/api/upload',
                        fieldName:'file',
                        meta:{
                            directory:'richtext'
                        },
                        customInsert(res, insertFn){
                          let imgurl = "http://114.67.170.224:21880/api/view?url=" + res.data.value
                          insertFn(imgurl)
                            // insertFn(res.data.url)
                        }
                    }
                }
          },
            mode: 'default', // or 'simple'
        }
    },
    created(){
    },
    methods: {
        onCreated(editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
        },
        onChange() {
            this.$emit("gethtml",this.html)
        }
    },
    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    }
})
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>